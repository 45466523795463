// Variables
@import 'variables';

// Bootstrap
@import 'bootstrap/dist/css/bootstrap.min.css';


// Font awesome
@import '~@fortawesome/fontawesome-free/css/all.css';

// flag icon
@import "~flag-icon-css/sass/flag-icons.scss";

//summernote
